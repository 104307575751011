import React from 'react'
import { SRLWrapper } from "simple-react-lightbox";
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import {Box,Flex,Heading,Text,Spinner} from '@chakra-ui/react'
//import Player from '../components/player'
import {motion} from 'framer-motion'
const bildname=[
  {'name':'Babette u. Henriette Bermann'},
  {'name':'Clara u. Moritz Marx'},
  {'name':'Elijahu'},
  {'name':'Emanuel Seligmann'},
  {'name':'Emauel u. Fanny Seligmann'},
  {'name':'Fanny Seligmann'},
  {'name':'Henriette Gerson'},
  {'name':'Henriette u. Simon Gerson'},
  {'name':'Hetche'},
  {'name':'Jehoschua'},
  {'name':'Josef u. Helena Gerson'},
  {'name':'Julius Perlstein'},
  {'name':'Leo Gerson'},
  {'name':'Leo Seligmann'},
  {'name':'Leopold Mayer'},
  {'name':'Loeb'},
  {'name':'Max Seligmann'},
  {'name':'Meir'},
  {'name':''},
  {'name':'Siegmund Seligmann'},
  {'name':''},
  {'name':''},
  {'name':''},
  {'name':''}
  
]

const options ={
  buttons: {
    showDownloadButton: false
  },
  thumbnails: {
    showThumbnails: true,
    thumbnailsAlignment: 'center',
    thumbnailsContainerBackgroundColor: 'transparent',
    thumbnailsContainerPadding: '0',
    thumbnailsGap: '0 1px',
    thumbnailsIconColor: '#ffffff',
    thumbnailsOpacity: 0.4,
    thumbnailsPosition: 'bottom',
    thumbnailsSize: ['100px', '100px']
  }
}

  function FriedhofOberwesel({data}) {
     
  if(data){
    return (
      <motion.div
      initial={{opacity:0,scale:0.9}}
      animate={{opacity:1,scale:1}}
      transition={{duration:.7,ease:"easeInOut"}}
      >
<Box pt="10">
      <div className="MyComponent">
        
        <SRLWrapper options={options}>
        <Heading variant="Titel" py="3">Jüdischer Friedhof von Oberwesel</Heading>
              <Text pl="1" variant="solid">Fotos von 2018,  ©Daniela Tobias </Text> 
              
             
        <Flex flexWrap="wrap" flexDirection={["column","column","row"]}>
        {data.myimages.edges.map((e,index) => {
           
          return (
             
              <Box key={index}  position="relative" overflow="hidden" m="1" maxWidth={['100%','100%','100%','49%']} maxHeight={[880,880,760]} cursor="pointer" >
             {/* <Center> */}
            { <GatsbyImage

              image={e.node.childImageSharp.gatsbyImageData}

             alt={bildname[index].name}  

            /> }
            {/* </Center> */}
         <Box bottom="0" position="absolute" zIndex="34" bg="gray.600"    > 
         <Text color="white"  textAlign="center" px="2"  fontSize="sm"> {bildname[index]?.name!==undefined?bildname[index].name:""}</Text></Box> 
         </Box>
     
          )

        })}
    </Flex>
        </SRLWrapper>

      </div>
      <Text fontSize="xs" >©Daniela Tobias</Text>
      </Box>
      </motion.div>
    );
      }
      return(<Spinner/>)
  }

  export const query = graphql`

  {

    myimages: allFile(
      filter: { relativeDirectory: { eq: "foberwesel" } }
      sort: { order: ASC, fields: base }
    ) {
      edges {

        node {

          base

          id

          relativePath

          childImageSharp {
             gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              transformOptions: {
                cropFocus: ATTENTION
                fit: CONTAIN
                
              }
              
            )
          }

        }

      }

    }
    thumbnails: allFile(
        filter: { relativeDirectory: { eq: "foberwesel/thumbnails" } }
        sort: { order: ASC, fields: base }
  
      ) {
  
        edges {
  
          node {
  
            base
  
            id
  
            relativePath
  
            childImageSharp {
  
              gatsbyImageData(
  
                placeholder: BLURRED
  
                layout: CONSTRAINED
  
                width: 120
  
                height: 120
  
              )
  
            }
  
          }
  
        }
  
      }
  
    

  }`


  export default FriedhofOberwesel;




